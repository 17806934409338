// ナビゲーション
const navTrigger = document.getElementsByClassName('nav-trigger')[0];
const body = document.getElementsByTagName('body')[0];

navTrigger.addEventListener('click', toggleNavigation);

function toggleNavigation(event) {
	event.preventDefault();
	body.classList.toggle('nav-open');

	if (body.classList.contains('nav-open')) {
		// スクロール禁止
		no_scroll();
	} else {
		// スクロール解放
		return_scroll();
	}
}

let listArray = document.querySelectorAll('nav a, .overlay');
for (let i = 0; i < listArray.length; i++) {
	listArray[i].onclick = function () {
		body.classList.remove('nav-open');

		// スクロール解放
		return_scroll();
	};
}

// スマホ画面回転で初期に戻す
window.addEventListener('orientationchange', () => {
	document.querySelector('body').removeAttribute('style');
	body.classList.remove('nav-open');

	// スクロール解放
	return_scroll();
	// console.log('回転');
});

// スクロールイベント
function scroll_control(event) {
	event.preventDefault();
}
// スクロール禁止
function no_scroll() {
	document.addEventListener('wheel', scroll_control, { passive: false });
	document.addEventListener('touchmove', scroll_control, { passive: false });
}
// スクロール解放
function return_scroll() {
	document.removeEventListener('wheel', scroll_control, { passive: false });
	document.removeEventListener('touchmove', scroll_control, { passive: false });
}

import Swiper from 'swiper';
import 'remodal';

// スクロール
var Ease = {
	easeInOut: (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
};
var duration = 500;
window.addEventListener('DOMContentLoaded', () => {
	var smoothScrollTriggers = document.querySelectorAll('.anchor');
	smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
		smoothScrollTrigger.addEventListener('click', function (e) {
			var href = smoothScrollTrigger.getAttribute('href');
			var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
			var targetElement = document.getElementById(href.replace('#', ''));
			if (targetElement) {
				e.preventDefault();
				e.stopPropagation();
				var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - 0;
				var startTime = performance.now();
				var loop = function (nowTime) {
					var time = nowTime - startTime;
					var normalizedTime = time / duration;
					if (normalizedTime < 1) {
						window.scrollTo(0, currentPostion + (targetPosition - currentPostion) * Ease.easeInOut(normalizedTime));
						requestAnimationFrame(loop);
					} else {
						window.scrollTo(0, targetPosition);
					}
				};
				requestAnimationFrame(loop);
			}
		});
	});
});

window.addEventListener('load', () => {
	/////// KEY VISUAL フェーダー ///////
	let fader = document.querySelectorAll('.fader');
	for (let i = 0; i < fader.length; i++) {
		const swiper = document.querySelectorAll('.fader .swiper-container')[i];
		const swiperSlide = swiper.querySelectorAll('.fader .swiper-slide').length;
		if (swiperSlide <= 1) {
			new Swiper(swiper, {
				watchOverflow: true,
			});
		} else {
			const mySwiper = new Swiper(swiper, {
				loop: true,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
				// watchOverflow: true, // loopと併用不可
				speed: 800,
				autoplay: {
					delay: 1500,
					disableOnInteraction: false,
				},
			});

			mySwiper.autoplay.stop();

			setTimeout(() => {
				mySwiper.autoplay.start();
			}, 1000);
		}
	}

	/////// スライダー ホーム ///////
	// Pick Up Item
	let slideHomeP = document.querySelectorAll('.home .pickup .slide');
	for (let i = 0; i < slideHomeP.length; i++) {
		const swiper = document.querySelectorAll('.home .pickup .swiper-container')[i];
		new Swiper(swiper, {
			// loop: true,
			watchOverflow: true,
			slidesPerView: 1.6,
			spaceBetween: 20,
			speed: 400,
			// pagination: {
			// 	el: document.querySelectorAll('.home .pickup .swiper-pagination')[i],
			// 	type: 'progressbar',
			// 	// type: 'bullets',
			// 	clickable: false,
			// },
			scrollbar: {
				el: document.querySelectorAll('.home .pickup .swiper-scrollbar')[i],
				hide: false,
				draggable: false,
			},
			navigation: {
				nextEl: document.querySelectorAll('.home .pickup .swiper-button-next')[i],
				prevEl: document.querySelectorAll('.home .pickup .swiper-button-prev')[i],
			},
			breakpoints: {
				559: {
					spaceBetween: 40,
					slidesPerView: 4,
				},
			},
		});
	}

	// NEWS
	let slideHomeN = document.querySelectorAll('.home .news .slide');
	for (let i = 0; i < slideHomeN.length; i++) {
		const swiper = document.querySelectorAll('.home .news .swiper-container')[i];
		new Swiper(swiper, {
			// loop: true,
			watchOverflow: true,
			slidesPerView: 1.4,
			spaceBetween: 15,
			speed: 400,

			// pagination: {
			// 	el: document.querySelectorAll('.home .news .swiper-pagination')[i],
			// 	type: 'progressbar',
			// 	clickable: false,
			// },
			scrollbar: {
				el: document.querySelectorAll('.home .news .swiper-scrollbar')[i],
				hide: false,
				draggable: false,
			},
			navigation: {
				nextEl: document.querySelectorAll('.home .news .swiper-button-next')[i],
				prevEl: document.querySelectorAll('.home .news .swiper-button-prev')[i],
			},
			breakpoints: {
				559: {
					spaceBetween: 20,
					slidesPerView: 4.5,
				},
			},
		});
	}

	/////// スライダー アイライナー ///////
	// PRODUCT
	let slide = document.querySelectorAll('.eyeliner .product .slide');
	for (let i = 0; i < slide.length; i++) {
		const swiperVisual = document.querySelectorAll('.eyeliner .product .prod')[i];
		const swiperItem = document.querySelectorAll('.eyeliner .product .detail')[i];
		const option = {
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			speed: 400,
			loop: true,
			// allowTouchMove: false,
			// touchRatio: 0,
			// watchSlidesVisibility: true,
			// watchSlidesProgress: true,
			autoHeight: true,
			on: {
				slideChange: function () {
					mainSlider.slideToLoop(itemSlider.realIndex);
				},
			},
		};
		const itemSlider = new Swiper(swiperItem, option);
		const mainSlider = new Swiper(swiperVisual, {
			loop: true,
			// watchOverflow: true,
			slidesPerView: 1,
			centeredSlides: true,
			spaceBetween: 0,
			speed: 400,
			pagination: {
				el: document.querySelectorAll('.eyeliner .product .swiper-pagination')[i],
				type: 'fraction',
			},
			navigation: {
				nextEl: document.querySelectorAll('.eyeliner .product .swiper-button-next')[i],
				prevEl: document.querySelectorAll('.eyeliner .product .swiper-button-prev')[i],
			},
			breakpoints: {
				559: {
					slidesPerView: 6.5,
				},
			},
			on: {
				slideChange: function () {
					itemSlider.slideToLoop(mainSlider.realIndex);
				},
			},
		});
	}

	// HOWTO
	let slide2 = document.querySelectorAll('.eyeliner .howto .slide');
	for (let i = 0; i < slide2.length; i++) {
		const swiper = document.querySelectorAll('.eyeliner .howto .swiper-container')[i];
		new Swiper(swiper, {
			loop: true,
			watchOverflow: true,
			slidesPerView: 1,
			centeredSlides: true,
			spaceBetween: 20,
			speed: 400,

			pagination: {
				el: document.querySelectorAll('.eyeliner .howto .swiper-pagination')[i],
				type: 'fraction',
			},
			navigation: {
				nextEl: document.querySelectorAll('.eyeliner .howto .swiper-button-next')[i],
				prevEl: document.querySelectorAll('.eyeliner .howto .swiper-button-prev')[i],
			},
			breakpoints: {
				559: {
					spaceBetween: 40,
					slidesPerView: 2,
				},
			},
		});
	}

	/////// マスカラ ///////
	// タブ
	let myTarget = document.querySelectorAll('.mascara .product .product__navi label');
	for (let i = 0; i < myTarget.length; i++) {
		myTarget[i].addEventListener('click', () => {
			setTimeout(() => {
				document.querySelector('.product #label' + i).checked = true;
			}, 900);

			document.querySelector('.mascara .product .targets').classList.remove('show');
			setTimeout(() => {
				document.querySelector('.mascara .product .targets').classList.add('show');
			}, 400);
		});
	}

	// スライダー
	// HOW to 3650 Mascara
	let slideMascaraH = document.querySelectorAll('.mascara .howto .slide');
	for (let i = 0; i < slideMascaraH.length; i++) {
		const swiper = document.querySelectorAll('.mascara .howto .swiper-container')[i];
		new Swiper(swiper, {
			// loop: true,
			watchOverflow: true,
			slidesPerView: 1.4,
			spaceBetween: 15,
			speed: 400,

			// pagination: {
			// 	el: document.querySelectorAll('.mascara .howto .swiper-pagination')[i],
			// 	type: 'progressbar',
			// 	clickable: false,
			// },
			scrollbar: {
				el: document.querySelectorAll('.mascara .howto .swiper-scrollbar')[i],
				hide: false,
				draggable: false,
			},
			navigation: {
				nextEl: document.querySelectorAll('.mascara .howto .swiper-button-next')[i],
				prevEl: document.querySelectorAll('.mascara .howto .swiper-button-prev')[i],
			},
			breakpoints: {
				559: {
					spaceBetween: 20,
					slidesPerView: 2.3,
				},
			},
		});
	}

	// STORY
	let slideMascaraS = document.querySelectorAll('.mascara .story .slide');
	for (let i = 0; i < slideMascaraS.length; i++) {
		let mySwiper = null;

		const mediaQuery = window.matchMedia('(max-width: 559px)');

		const checkBreakpoint = (e) => {
			if (e.matches) {
				initSwiper();
			} else if (mySwiper) {
				mySwiper.destroy(false, true);
			}
		};

		const initSwiper = () => {
			const swiperVisual = document.querySelectorAll('.mascara .story .swiper-container')[i];
			mySwiper = new Swiper(swiperVisual, {
				loop: true,
				spaceBetween: 30,
				speed: 400,
				watchOverflow: true,
				slidesPerView: 1.4,
				centeredSlides: true,
				pagination: {
					el: document.querySelectorAll('.mascara .story .swiper-pagination')[i],
					type: 'fraction',
					clickable: false,
				},
				navigation: {
					nextEl: document.querySelectorAll('.mascara .story .swiper-button-next')[i],
					prevEl: document.querySelectorAll('.mascara .story .swiper-button-prev')[i],
				},
			});
		};

		// mediaQuery.addListener(checkBreakpoint);
		mediaQuery.addEventListener('change', checkBreakpoint);
		checkBreakpoint(mediaQuery);
	}

	// スクロールアニメーション
	if (document.querySelector('main').classList.contains('mascara')) {
		// マスカラページ GSAP
		let controller = new ScrollMagic.Controller();
		let t4 = gsap.timeline();
		t4.to('.scene1', 4, {
			autoAlpha: 0,
			delay: 3,
		})
			.from(
				'.scene2',
				4,
				{
					autoAlpha: 0,
					delay: 0,
				},
				'-=4'
			)
			.from('.scene3', 4, {
				autoAlpha: 0,
				delay: 3,
			})
			.from('.scene4', 4, {
				autoAlpha: 0,
				delay: 3,
			});

		new ScrollMagic.Scene({
			triggerElement: '.function',
			duration: 6000,
			triggerHook: 0,
			offset: '50',
		})
			.setTween(t4)
			.setPin('.function')
			.addTo(controller);
	}

	// ブラウザの描画準備ができたら実行
	requestAnimationFrame(() => {
		// スクロールアニメーション
		funcAnim();
	});

	let startPos = 0;
	window.addEventListener('scroll', () => {
		// キービジュアル通過でclass追加
		let value = document.documentElement.scrollTop || document.body.scrollTop;
		startPos = value;
		let topbar = document.querySelector('.keyvisual').clientHeight - 30;
		if (value > topbar) {
			document.querySelector('.keyvisual').classList.add('hidden');
			document.querySelector('header').classList.add('show');
		} else {
			document.querySelector('.keyvisual').classList.remove('hidden');
			document.querySelector('header').classList.remove('show');
		}

		// スクロールアニメーション
		funcAnim();
	});
});

function funcAnim() {
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	let myMain = document.querySelectorAll('.anim');
	for (let i = 0; i < myMain.length; i++) {
		let rect = myMain[i].getBoundingClientRect();
		let posiTop = rect.top + scrollTop; // ターゲットがブラウザTOPに来たら
		// let posiMid = posiTop - window.innerHeight / 2; // ターゲットがブラウザMIDに来たら
		let posiBtm = posiTop - window.innerHeight; // ターゲットがブラウザBOTOMに来たら
		let myHeight = myMain[i].clientHeight - 0;
		let position_bottom = posiTop + myHeight; // ターゲットが下からスクロールしたときに見える位置

		// ターゲットが来た時ときの動き
		if (scrollTop > posiBtm && scrollTop < position_bottom) {
			myMain[i].classList.add('active');
			// console.log('きた');
		}
		// else {
		// 	// それ以外の動き
		// 	myMain[i].classList.remove('active');
		// }
	}
}
